import { ITimeActivity } from '../../types/activity.type';
import { Observable } from 'rxjs';
import { Localizations, CMSResponse } from '../../types/common.type';
import { IFile } from '../../types/common.type';

export const timeLine: any[] = [
    {
        active: true,
        year: '2024',
        stepMonth: [
            '30/12',
            '20/10',
            '12/10',
            '01/10',
            '15/09',
            '01/06',
            '30/04',
            '08/03',
        ],
    },
    {
        active: false,
        year: '2023',
        stepMonth: ['22/11', '20/10', '15/09', '01/06', '30/04', '08/03'],
    },
    {
        active: false,
        year: '2022',
        stepMonth: ['15/12', '20/10', '15/09', '01/06', '30/04', '08/03'],
    },
    {
        active: false,
        year: '2021',
        stepMonth: ['30/11', '20/10', '15/09', '01/06', '30/04', '08/03'],
    },
    {
        active: false,
        year: '2020',
        stepMonth: ['27/12', '20/10', '15/09', '01/06', '30/04', '08/03'],
    },
    {
        active: false,
        year: '2019',
        stepMonth: ['14/12', '20/10', '15/09', '01/06', '30/04', '08/03'],
    },
];
export interface IActivity {
    id: number;
    attributes: {
        Name: string;
        Date: string;
        TimeLineDate: string;
        Description: string;
        Media: {
            data: IFile[];
        };
        locale?: string;
        localizations?: Localizations;
        createdAt: string;
        updatedAt: string;
        publishedAt?: string;
    };
}
export interface IGalleryPage {
    id: number;
    attributes: {
        Banner: IFile[];
        Events: Event[];
    };
}
export abstract class ActivityData {}

<div
    id="footer"
    class="bg-[#0A0601] relative overflow-hidden before:bg-[url('assets/image/pages/footer/background_footer.png')] before:bg-no-repeat before:w-[747px] before:h-[517px] before:absolute before:right-0 before:z-[1] before:bg-right-bottom max-lg:before:h-full max-lg:before:w-full max-lg:before:bg-center-top max-md:before:bg-[url('assets/image/pages/footer/background_footer_mobile.png')] max-md:before:bg-contain max-md:before:bg-top"
>
    <div
        class="mx-auto max-w-[1175px] px-[15px] pt-[30px] pb-[15px] flex justify-center gap-x-20 z-10 relative max-lg:flex-col max-lg:px-8 max-lg:pb-9 max-lg:pt-10"
    >
        <div
            class="grid grid-cols-2 py-6 max-lg:flex max-lg:flex-col max-lg:order-2 max-lg:mt-9 max-lg:py-0"
        >
            <div
                class="flex flex-col gap-y-[15px] justify-between max-lg:items-center max-lg:gap-y-4"
            >
                @for (item of infoContactService()?.values | slice : 0 : 3 ;
                track $index) {
                <div class="max-lg:flex max-lg:flex-col max-lg:items-center">
                    <ng-container
                        [ngTemplateOutlet]="info"
                        [ngTemplateOutletContext]="{
                    item: item,
                }"
                    ></ng-container>
                </div>

                }
            </div>
            <div
                class="flex flex-col gap-y-[15px] max-lg:items-center max-lg:gap-y-4 max-lg:mt-6"
            >
                @for (item of infoContactService()?.values | slice : 3 : 5 ;
                track $index) {
                <div class="max-lg:flex max-lg:flex-col max-lg:items-center">
                    <ng-container
                        [ngTemplateOutlet]="info"
                        [ngTemplateOutletContext]="{
                    item: item,
                }"
                    ></ng-container>
                </div>

                }
            </div>
        </div>
        <div
            class="flex flex-col gap-y-[50px] pl-[80px] border-l-[0.5px] border-white/20 border-solid py-[30px] max-lg:order-1 max-lg:border-0 max-lg:p-0"
        >
            <div class="max-lg:px-[41px]">
                <a
                    [routerLink]="
                        localeDefaultService()?.code === 'en' ? '/en' : ''
                    "
                    [rel]="
                        localeDefaultService()?.code === 'en'
                            ? 'nofollow'
                            : 'dofollow'
                    "
                >
                    <img
                        src="assets/image/global/logo_footer.svg"
                        alt="logo Volio Group"
                        title="logo Volio Group"
                        class="mx-auto"
                        width=""
                        height=""
                        loading="lazy"
                    />
                </a>
            </div>
            <div class="max-lg:flex max-lg:justify-center">
                <div class="fb">
                    <iframe
                        src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fvolio.group&tabs=timeline&width=264&height=130&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                        width="264"
                        height="130"
                        style="border: none; overflow: hidden"
                        scrolling="no"
                        frameborder="0"
                        allowfullscreen="true"
                        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
                    ></iframe>
                </div>
            </div>
        </div>
    </div>
    <div
        class="border-t-[0.5px] border-white/20 border-solid mx-6 px-6 z-10 relative"
    >
        <nav
            id="nav_footer"
            class="pt-5 mx-auto max-w-[1265px] max-lg:py-[35px]"
        >
            <ul
                id="navigation_footer"
                class="flex items-center justify-between max-lg:flex-col"
            >
                @for (item of menuService()?.values; track $index) {
                @if(item.description.indexOf( '/tuyen-dung' ) >= 0 ||
                item.description.indexOf( '/recruit' ) >= 0 ||
                item.description.indexOf( '/su-kien' ) >= 0 ||
                item.description.indexOf( '/event' ) >= 0){
                <li
                    class="leading-[32px] text-white text-base font-semibold uppercase max-lg:text-sm"
                >
                    <a
                        class="px-8 py-4 block max-2xl:px-4"
                        [routerLink]="item.description"
                        [routerLinkActiveOptions]="{
                            exact: false
                        }"
                        [routerLinkActive]="['is-active']"
                        >{{ item.title }}
                    </a>
                </li>
                } @else if (item.description.indexOf('/ecosystem') >= 0) {
                <li
                    class="leading-[32px] relative cursor-pointer text-white text-base font-semibold uppercase max-lg:text-sm"
                >
                    <div
                        class="item-ecosystem relative mx-4 px-4 py-4 block max-2xl:mx-2 max-2xl:px-2 max-xl:mx-1"
                        (click)="op.toggle($event)"
                        [class.is-active]="isLinkActive(item.description)"
                    >
                        {{ item.title }}
                    </div>
                    <p-overlayPanel #op [style]="{ width: '150px' }">
                        <nav>
                            <ul>
                                @for (item of ecosystems; track $index) {
                                <li
                                    class="bg-white text-[14px] text-black font-bold"
                                >
                                    <a
                                        class="block p-2"
                                        [routerLink]="'/ecosystem/' + item.link"
                                        [routerLinkActiveOptions]="{
                                            exact: true
                                        }"
                                        [routerLinkActive]="['is-active-item']"
                                        (click)="op.toggle($event)"
                                    >
                                        {{ item.title }}
                                    </a>
                                </li>
                                }
                            </ul>
                        </nav>
                    </p-overlayPanel>
                </li>
                }@else {
                <li
                    class="leading-[32px] text-white text-base font-semibold uppercase max-lg:text-sm"
                >
                    <a
                        class="px-8 py-4 block max-2xl:px-4"
                        [routerLink]="item.description"
                        [routerLinkActiveOptions]="{
                            exact: true
                        }"
                        [routerLinkActive]="['is-active']"
                        >{{ item.title }}
                    </a>
                </li>
                } }
            </ul>
        </nav>
    </div>
</div>

<ng-template #info let-item="item">
    <div class="flex items-center">
        <img
            [src]="item?.assets[0].link"
            alt=""
            loading="lazy"
            width="40"
            height="40"
        />
        <p class="font-bold text-[14px] leading-6 text-[#575757] font-inter">
            {{ item.title }}
        </p>
    </div>
    <p
        class="font-bold text-[16px] leading-8 text-white mt-1 max-lg:text-[14px] max-lg:leading-6 max-lg:text-center"
    >
        {{ item.description }}
    </p>
</ng-template>

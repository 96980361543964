<div
    class="error-main mt-[146px] mb-[261px] h-[808px] max-lg:h-full max-lg:mt-24 max-lg:mb-24 max-sm:mt-[80px] max-sm:mb-[76px]"
>
    <div
        class="h-full flex items-end justify-center flex-row-reverse gap-x-[35px] max-lg:block"
    >
        <div class="max-lg:w-full max-lg:pb-[60px]">
            <img
                src="assets/image/pages/error/img_ufo.png"
                width="555"
                height="675"
                class="h-[675px] max-lg:mx-auto max-lg:h-[400px] max-lg:w-[400px] max-sm:w-[200px] max-sm:h-[198px]"
                alt=""
            />
        </div>
        <div
            class="text-center pb-[70px] max-lg:pb-10 max-md:px-[22px] max-md:pt-[22px] max-md:pb-[17px]"
        >
            <div>
                <h1
                    class="font-black text-[156px] leading-[116px] p-0 max-lg:text-[100px] max-lg:leading-[80px] max-sm:text-[70px] max-sm:leading-[56px]"
                >
                    404
                </h1>
                <h3
                    class="uppercase font-extrabold text-gradient p-0 text-[96px] leading-[86px] max-lg:text-[70px] max-lg:leading-[60px] max-sm:text-[44px] max-sm:leading-[50px]"
                >
                    error
                </h3>
                <h4
                    class="mt-[10px] text-[43px] leading-[38px] font-extrabold text-[#585b61] max-lg:text-[36px] max-lg:mt-3 max-sm:text-[28px] max-sm:leading-[25px]"
                >
                    {{ "error.page_not_found" | translate }}
                </h4>
                <p
                    class="text-[#585b61] text-[18px] leading-8 font-bold mt-[15px] max-lg:mt-[9px] max-sm:text-[11px] max-sm:leading-3"
                >
                    {{ "error.404" | translate }}
                </p>
            </div>
            <button
                    class="bg-colorMain rounded-[70px] px-[113px] py-[18px] text-xl leading-8 font-bold mt-[70px] max-lg:px-[80px] max-lg:mt-12 max-sm:text-[14px] max-sm:leading-[23px] max-sm:px-20 max-sm:mt-8 max-sm:py-[12.5px]"
                    [routerLink]="
                    localeDefaultService()?.code === 'en' ? '/en' : ''
                "
                >
                    GO BACK
                </button>
        </div>
    </div>
</div>

import { inject, Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpEvent,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse,
    HttpParams,
} from '@angular/common/http';
import { Observable, map, of, throwError, catchError } from 'rxjs';
import { Router } from '@angular/router';
import { ValueGlobalService } from '@services/global/value.global.service';

@Injectable({
    providedIn: 'root',
})
export class VolioAuthInterceptor implements HttpInterceptor {
    private router = inject(Router);

    readonly #valueGlobalService = inject(ValueGlobalService);
    readonly localeDefaultService = this.#valueGlobalService.locale;

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (req.headers.has('Error-Handler')) {
            const headers = req.headers.delete('Error-Handler');
            const clonedRequest = req.clone({ headers });
            return next.handle(clonedRequest);
        }
        const newReq = req.clone({
            params: (req.params ? req.params : new HttpParams())
                       .set('locale', this.localeDefaultService()?.code || 'vi')
          });
        return next
            .handle(newReq)
            .pipe(catchError((x) => this.handleAuthError(x)));
    }

    private handleAuthError(err: HttpErrorResponse): Observable<any> {
        console.log('err', err);

        if (err.status === 400) {
            this.router.navigateByUrl(`/404`);
            return of(err.message);
        }

        return throwError(err);
    }
}

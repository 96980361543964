import { AppsManagementResponse, CMSResponse } from '../../types/common.type';
import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import {
    Career,
    CareerPage,
    RecruitData,
    TitleInCareer,
} from '@shared/data/recruit.data';
import { Observable } from 'rxjs';
import { environment } from '@environment/environment';
import { DatePipe } from '@angular/common';
import { ISpecializes, SpecializesData } from '@shared/data/specializes.data';

@Injectable({
    providedIn: 'root',
})
export class SpecializeService extends SpecializesData {
    private HttpClient = inject(HttpClient);

    constructor() {
        super();
    }
    override GetSpecializes(): Observable<CMSResponse<ISpecializes[]>> {
        let url = `${environment.clientUrl}/specializes`;
        return this.HttpClient.get<CMSResponse<ISpecializes[]>>(url);
    }
}

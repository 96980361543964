import {
    APP_INITIALIZER,
    ApplicationConfig,
    importProvidersFrom,
    provideExperimentalZonelessChangeDetection,
} from '@angular/core';
import {
    InMemoryScrollingFeature,
    InMemoryScrollingOptions,
    PreloadAllModules,
    TitleStrategy,
    provideRouter,
    withInMemoryScrolling,
    withPreloading,
    Routes,
    ROUTES,
} from '@angular/router';

import { routes } from './config/routes/app.routes';
import {
    BrowserModule,
    provideClientHydration,
    withHttpTransferCacheOptions,
} from '@angular/platform-browser';
import {
    BrowserAnimationsModule,
    provideAnimations,
} from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
    HTTP_INTERCEPTORS,
    HttpClient,
    provideHttpClient,
    withFetch,
    withInterceptorsFromDi,
} from '@angular/common/http';
import { ConfirmLeaveGuard } from '@guards/confirm-leave.guard';
import { provideToastr } from 'ngx-toastr';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpLoaderFactory } from './utils/http-loader-factory';
import { CORE_PROVIDERS } from '@config/core.module';
import { provideLottieOptions } from 'ngx-lottie';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LanguageService } from '@services/global/language.service';
import { InitService } from '@services/init/init.service';
import { VolioAuthInterceptor } from './interceptor/auth.interceptor';

const scrollConfig: InMemoryScrollingOptions = {
    scrollPositionRestoration: 'enabled', // set là top thì khi back lại nó sẽ scroll lên đầu page, enabled thì back lại giữ nguyên vị trí cũ
    anchorScrolling: 'enabled',
};

const inMemoryScrollingFeature: InMemoryScrollingFeature =
    withInMemoryScrolling(scrollConfig);

export function initializeAppDetail(initService: InitService) {
    return () => {
        return initService.init();
    };
}

export const appConfig: ApplicationConfig = {
    providers: [
        LanguageService,
        // {
        //     provide: ROUTES,
        //     useFactory: (languageService: LanguageService) =>
        //         createRoutes(languageService),
        //     deps: [LanguageService],
        //     multi:true
        // },
        importProvidersFrom([
            BrowserModule,
            BrowserAnimationsModule,
            NgxSpinnerModule.forRoot({ type: 'square-jelly-box' }),
        ]),
        provideRouter(routes, inMemoryScrollingFeature),
        // provideClientHydration(),
        provideClientHydration(
            withHttpTransferCacheOptions({
                includePostRequests: true,
            })
        ),
        provideAnimations(),
        provideHttpClient(withFetch(), withInterceptorsFromDi()),
        provideToastr(),
        provideExperimentalZonelessChangeDetection(),
        ConfirmLeaveGuard,
        CORE_PROVIDERS,
        importProvidersFrom([
            BrowserModule,
            BrowserAnimationsModule,
            TranslateModule.forRoot({
                loader: {
                    provide: TranslateLoader,
                    useFactory: HttpLoaderFactory,
                    deps: [HttpClient],
                },
            }),
        ]),
        provideLottieOptions({
            player: () => import('lottie-web'),
        }),
        {
            provide: APP_INITIALIZER,
            useFactory: initializeAppDetail,
            deps: [InitService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: VolioAuthInterceptor,
            multi: true,
        },
    ],
};

import { isPlatformServer } from '@angular/common';
import { Component, HostListener, inject, PLATFORM_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { Router, RouterLink } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ValueGlobalService } from '@services/global/value.global.service';
import { Response } from 'express';
import { RESPONSE } from '../../../express.tokens';

@Component({
    selector: 'app-error-not-found',
    standalone: true,
    imports: [FormsModule, TranslateModule, RouterLink],
    templateUrl: './error-not-found.component.html',
    styleUrl: './error-not-found.component.scss',
})
export class ErrorNotFoundComponent {
    private translate = inject(TranslateService);
    private router = inject(Router);
    private titleService = inject(Title);
    private platformId = inject(PLATFORM_ID);
    private response = inject<Response>(RESPONSE, { optional: true });

    readonly #valueGlobalService = inject(ValueGlobalService);
    readonly localeDefaultService = this.#valueGlobalService.locale;

    @HostListener('window:popstate', ['$event'])
    onPopState(event) {

        this.router.navigateByUrl(this.localeDefaultService()?.code === 'en' ? '/en' : '');
    }

    ngOnInit(): void {
        this.translate.setDefaultLang(
            this.localeDefaultService()?.code
            ? this.localeDefaultService()?.code
            : 'vi'
        );

        if (this.localeDefaultService()?.code === 'en') {
            this.titleService.setTitle('Not Found - Volio Group');
        }

        if (isPlatformServer(this.platformId)) {
            if (this.response) {
                this.response.status(404);
            }
        }
    }
}

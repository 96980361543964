import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environment/environment';
import { AppsManagementResponse, ISeo } from '../../types/common.type';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SeoService {
    private HttpClient = inject(HttpClient);

    getSeos(): Observable<AppsManagementResponse<ISeo[]>> {
        let url = `${environment.clientUrl}/seos?limit=100&offset=0`;
        return this.HttpClient.get<AppsManagementResponse<ISeo[]>>(url);
    }
}

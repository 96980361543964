@if( menus()) {
<div id="header">
    <div
        [ngClass]="showScroll ? 'header-up' : 'header-down'"
        class="bg-bgHeader py-[8px] w-full z-[9999] fixed top-0"
    >
        <div
            class="flex items-center justify-center gap-x-5 px-[15px] max-lg:justify-between"
        >
            <div class="header-logo mr-[60px] max-2xl:mr-[0px]">
                <a
                    [routerLink]="
                        localeDefaultService()?.code === 'en' ? '/en' : ''
                    "
                    [rel]="
                        localeDefaultService()?.code === 'en'
                            ? 'nofollow'
                            : 'dofollow'
                    "
                >
                    <img
                        src="assets/image/pages/header/logo.png"
                        alt="logo Volio Group"
                        title="logo Volio Group"
                        width="100%"
                        height="100%"
                    />
                </a>
            </div>
            <div class="max-lg:hidden">
                <nav id="nav">
                    <ul
                        id="navigation"
                        class="flex items-center justify-center"
                    >
                        @for (item of menus()?.values; track $index) {
                        @if(item.description.indexOf( '/tuyen-dung' ) >= 0 ||
                        item.description.indexOf( '/recruit' ) >= 0 ||
                        item.description.indexOf( '/su-kien' ) >= 0 ||
                        item.description.indexOf( '/event' ) >= 0){
                        <li
                            class="leading-[32px] text-white text-base font-semibold uppercase max-xl:text-sm"
                        >
                            <a
                                class="mx-4 px-4 py-4 block max-2xl:mx-2 max-2xl:px-2 max-xl:mx-1"
                                [routerLink]="item.description"
                                [routerLinkActiveOptions]="{
                                    exact: false
                                }"
                                [routerLinkActive]="['is-active']"
                                >{{ item.title }}
                            </a>
                        </li>
                        } @else if (item.description.indexOf('/ecosystem') >= 0)
                        {
                        <li
                            class="leading-[32px] relative cursor-pointer text-white text-base font-semibold uppercase max-xl:text-sm"
                        >
                            <div
                                class="item-ecosystem relative mx-4 px-4 py-4 block max-2xl:mx-2 max-2xl:px-2 max-xl:mx-1"
                                (click)="op.toggle($event)"
                                [class.is-active]="
                                    isLinkActive(item.description)
                                "
                            >
                                {{ item.title }}
                            </div>
                        </li>
                        } @else {
                        <li
                            class="leading-[32px] text-white text-base font-semibold uppercase max-xl:text-sm"
                        >
                            <a
                                class="mx-4 px-4 py-4 block max-2xl:mx-2 max-2xl:px-2 max-xl:mx-1"
                                [routerLink]="item.description"
                                [routerLinkActiveOptions]="{
                                    exact: true
                                }"
                                [routerLinkActive]="['is-active']"
                                >{{ item.title }}
                            </a>
                        </li>
                        } }
                    </ul>
                </nav>
            </div>
            <div class="flex items-center gap-x-6">
                <div
                    class="card flex justify-content-center language pl-[60px] max-2xl:pl-[30px]"
                >
                    <p-dropdown
                        [options]="countries"
                        [ngModel]="selectedCountry()"
                        optionLabel="name"
                        placeholder=""
                        (onChange)="onCountryChange($event)"
                    >
                        <ng-template pTemplate="selectedItem">
                            <div
                                class="flex align-items-center gap-2 item-country"
                            >
                                <img
                                    [src]="selectedCountry().icon"
                                    style="width: 18px"
                                    alt=""
                                />
                                <div>{{ selectedCountry().name }}</div>
                            </div>
                        </ng-template>
                        <ng-template let-country pTemplate="item">
                            <div class="flex align-items-center gap-2">
                                <img [src]="country.icon" style="width: 18px" />
                                <div>{{ country.name }}</div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                </div>
                <div class="hidden max-lg:flex">
                    <div
                        class="inline-block cursor-pointer"
                        (click)="openTabMenu()"
                        [ngClass]="{ change: this.openMenuMobile }"
                    >
                        <div class="bar1"></div>
                        <div class="bar2"></div>
                        <div class="bar3"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
}

<div #menuMobile class="menu-mobile">
    <div (click)="openTabMenu()" class="close-menu-mobile">
        <span class="material-symbols-outlined"> cancel </span>
        <p class="text-white">{{ "common.close_tab" | translate }}</p>
    </div>
    <nav id="nav-mobile">
        <ul id="navigation-mobile" class="flex flex-col">
            @for (item of menus()?.values; track $index) {
                @if(item.description.indexOf( '/tuyen-dung' ) >= 0 ||
                item.description.indexOf( '/recruit' ) >= 0 ||
                item.description.indexOf( '/su-kien' ) >= 0 ||
                item.description.indexOf( '/event' ) >= 0){
            <li class="text-base capitalize max-sm:text-sm">
                <a
                    class="px-4 py-4 block max-2xl:px-2"
                    [routerLink]="item.description"
                    [routerLinkActiveOptions]="{
                        exact: false
                    }"
                    [routerLinkActive]="['is-active']"
                    (click)="closeTab()"
                    >{{ item.title }}
                </a>
            </li>
            } @else if (item.description.indexOf('/ecosystem') >= 0) {
            <li class="ecosystem text-base capitalize max-sm:text-sm">
                <!-- <div
                    class="item-ecosystem relative cursor-pointer px-4 py-4 block max-2xl:px-2"
                    [class.is-active]="isLinkActive(item.description)"
                    (click)="menu.toggle($event)"
                >
                    {{ item.title }}
                </div> -->
                <div
                    class="item-ecosystem relative cursor-pointer px-4 py-4 block max-2xl:px-2"
                    (click)="op.toggle($event)"
                    [class.is-active]="
                        isLinkActive(item.description)
                    "
                >
                    {{ item.title }}
                </div>
                <!-- <p-menu
                    #menu
                    [model]="ecosystems"
                    [popup]="true"
                    [style]="{ left: '10px' }"
                >
                    <ng-template pTemplate="item" let-item>
                        <ng-container>
                            <a
                                class="block p-2 text-sm capitalize"
                                [routerLink]="'/ecosystem/' + item.link"
                                [routerLinkActiveOptions]="{
                                    exact: true
                                }"
                                [routerLinkActive]="['is-active-item']"
                                (click)="closeTab()"
                            >
                                {{ item.title }}
                            </a>
                        </ng-container>
                    </ng-template>
                </p-menu> -->
            </li>
            } @else {
            <li class="text-base capitalize max-sm:text-sm">
                <a
                    class="px-4 py-4 block max-2xl:px-2"
                    [routerLink]="item.description"
                    [routerLinkActiveOptions]="{
                        exact: true
                    }"
                    [routerLinkActive]="['is-active']"
                    (click)="closeTab()"
                    >{{ item.title }}
                </a>
            </li>
            } }
        </ul>
    </nav>
</div>

<p-overlayPanel #op [baseZIndex]="99999" [style]="{ width: '150px', left: '10px' }">
    <nav>
        <ul>
            @for (item of ecosystems; track $index) {
            <li class="bg-white text-[14px] text-black font-semibold max-lg:text-sm max-lg:capitalize max-lg:font-normal">
                <a
                    class="block p-2"
                    routerLink="/ecosystem/{{ item.link }}/{{
                        this.localeDefaultService()?.code === 'en' ? '/en' : ''
                    }}"
                    [routerLinkActiveOptions]="{
                        exact: true
                    }"
                    [routerLinkActive]="['is-active-item']"
                    (click)="op.hide();  closeTab()"
                >
                    {{ item.title }}
                </a>
            </li>
            }
        </ul>
    </nav>
</p-overlayPanel>

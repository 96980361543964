import { Observable } from 'rxjs';
import { environment } from '@environment/environment';
import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ContactData, IContact } from '@shared/data/contact.data';
import { CMSResponse } from '../../types/common.type';

@Injectable({
    providedIn: 'root',
})
export class ContactService extends ContactData {
    private HttpClient = inject(HttpClient);

    constructor() {
        super();
    }
    override PostInforContact(
        data: IContact
    ): Observable<CMSResponse<IContact>> {
        let url = `${environment.clientUrl}/recruitments`;
        return this.HttpClient.post<CMSResponse<IContact>>(url, data);
    }
}

import { TitleStrategy } from '@angular/router';
import { ActivityService } from '@services/activity/activity.service';
import { EventService } from '@services/event/event.service';
import { RecruitService } from '@services/recruit/recruit.service';
import { FileImageService } from '@services/upload/file-upload.service';
import { ActivityData } from '@shared/data/activity.data';
import { EventData } from '@shared/data/event.data';
import { FileImageData } from '@shared/data/file.data';
import { RecruitData } from '@shared/data/recruit.data';
import { CommonTitleStrategy } from './title.strategy';
import { ContactData } from '@shared/data/contact.data';
import { ContactService } from '@services/contact/contact.service';
import { SpecializesData } from '@shared/data/specializes.data';
import { SpecializeService } from '@services/specializes/specializes.service';
import { StructureDataService } from '@services/global/structureData.service';

export const CORE_PROVIDERS = [
    {
        provide: FileImageData,
        useClass: FileImageService,
    },
    {
        provide: RecruitData,
        useClass: RecruitService,
    },
    {
        provide: EventData,
        useClass: EventService,
    },
    {
        provide: ActivityData,
        useClass: ActivityService,
    },
    {
        provide: TitleStrategy,
        useClass: CommonTitleStrategy,
    },
    {
        provide: ContactData,
        useClass: ContactService,
    },
    {
        provide: SpecializesData,
        useClass: SpecializeService,
    },
    StructureDataService,
];

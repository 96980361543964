import { Injectable } from '@angular/core';
import { CanActivate, CanDeactivate, Router } from '@angular/router';
import { ContactComponent } from '@pages/contact/contact.component';
import { RecruitDetailComponent } from '@pages/recruit/children/recruit-detail.component';

@Injectable({
    providedIn: 'root',
})
export class ConfirmLeaveGuard
    implements CanDeactivate<RecruitDetailComponent | ContactComponent>
{
    canDeactivate(component: RecruitDetailComponent | ContactComponent) {
        return component.canDeactivate();
    }
}

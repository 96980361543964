import { Routes } from '@angular/router';
import { ConfirmLeaveGuard } from '@guards/confirm-leave.guard';
import { ErrorNotFoundComponent } from '@pages/error/error-not-found/error-not-found.component';

import { LanguageService } from '@services/global/language.service';

export const routes: Routes = [
    // {
    //     path: checkPath === 'en' ? 'en' : '',
    //     pathMatch: 'full',
    //     redirectTo: checkPath === 'en' ? 'en' : '',
    //     title: checkPath === 'en' ? 'Home' : 'Trang chủ',
    // },
    {
        path: '',
        loadComponent: () =>
            import('../../pages/home/home.component').then(
                (m) => m.HomeComponent
            ),
        title: '',
    },
    {
        path: 'en',
        loadComponent: () =>
            import('../../pages/home/home.component').then(
                (m) => m.HomeComponent
            ),
        title: '',
    },
    {
        path: 'gioi-thieu',
        loadComponent: () =>
            import('../../pages/introduce/introduce.component').then(
                (m) => m.IntroduceComponent
            ),
        title: 'Giới Thiệu',
    },
    {
        path: 'introduce',
        loadComponent: () =>
            import('../../pages/introduce/introduce.component').then(
                (m) => m.IntroduceComponent
            ),
        title: 'Introduce',
    },
    {
        path: 'van-hoa',
        loadComponent: () =>
            import('../../pages/culture/culture.component').then(
                (m) => m.CultureComponent
            ),
        title: 'Văn hóa',
    },
    {
        path: 'culture',
        loadComponent: () =>
            import('../../pages/culture/culture.component').then(
                (m) => m.CultureComponent
            ),
        title: 'Culture',
    },
    {
        path: 'ecosystem',
        children: [
            {
                path: '',
                loadComponent: () =>
                    import('../../pages/govo-tech/govo-tech.component').then(
                        (m) => m.GovotechComponent
                    ),
                title: 'Govo Tech',
            },
            {
                path: 'fidra',
                loadComponent: () =>
                    import('../../pages/fidra-ios/fidra-ios.component').then(
                        (m) => m.FidraIosComponent
                    ),
                title: 'Fidra',
            },
            {
                path: 'fidra/en',
                loadComponent: () =>
                    import('../../pages/fidra-ios/fidra-ios.component').then(
                        (m) => m.FidraIosComponent
                    ),
                title: 'Fidra',
            },
            {
                path: 'govo-tech',
                loadComponent: () =>
                    import('../../pages/govo-tech/govo-tech.component').then(
                        (m) => m.GovotechComponent
                    ),
                title: 'Govo Tech',
            },
            {
                path: 'govo-tech/en',
                loadComponent: () =>
                    import('../../pages/govo-tech/govo-tech.component').then(
                        (m) => m.GovotechComponent
                    ),
                title: 'Govo Tech',
            },
            {
                path: 'jacat-games',
                loadComponent: () =>
                    import(
                        '../../pages/jacat-games/jacat-games.component'
                    ).then((m) => m.JacatGamesComponent),
                title: 'Jacat Games',
            },
            {
                path: 'jacat-games/en',
                loadComponent: () =>
                    import(
                        '../../pages/jacat-games/jacat-games.component'
                    ).then((m) => m.JacatGamesComponent),
                title: 'Jacat Games',
            },
        ],
    },
    {
        path: 'su-kien',
        children: [
            {
                path: '',
                loadComponent: () =>
                    import('../../pages/event/event.component').then(
                        (m) => m.EventComponent
                    ),
                title: 'Sự kiện',
            },
            {
                path: ':id',
                loadComponent: () =>
                    import('../../pages/event/event.component').then(
                        (m) => m.EventComponent
                    ),
                title: 'Sự kiện',
            },
        ],
    },
    {
        path: 'event',
        children: [
            {
                path: '',
                loadComponent: () =>
                    import('../../pages/event/event.component').then(
                        (m) => m.EventComponent
                    ),
                title: 'Event',
            },
            {
                path: ':id',
                loadComponent: () =>
                    import('../../pages/event/event.component').then(
                        (m) => m.EventComponent
                    ),
                title: 'Event',
            },
        ],
    },
    {
        path: 'hoat-dong',
        loadComponent: () =>
            import('../../pages/activity/activity.component').then(
                (m) => m.ActivityComponent
            ),
        title: 'Hoạt động',
    },
    {
        path: 'activity',
        loadComponent: () =>
            import('../../pages/activity/activity.component').then(
                (m) => m.ActivityComponent
            ),
        title: 'Activity',
    },
    {
        path: 'tuyen-dung',
        children: [
            {
                path: '',
                loadComponent: () =>
                    import('../../pages/recruit/recruit.component').then(
                        (m) => m.RecruitComponent
                    ),
                title: 'Tuyển Dụng',
            },
            {
                path: ':id/:speacial',
                loadComponent: () =>
                    import(
                        '../../pages/recruit/children/recruit-detail.component'
                    ).then((m) => m.RecruitDetailComponent),
                canDeactivate: [ConfirmLeaveGuard],
            },
        ],
    },
    {
        path: 'recruit',
        children: [
            {
                path: '',
                loadComponent: () =>
                    import('../../pages/recruit/recruit.component').then(
                        (m) => m.RecruitComponent
                    ),
                title: 'Recruit',
            },
            {
                path: ':id/:speacial',
                loadComponent: () =>
                    import(
                        '../../pages/recruit/children/recruit-detail.component'
                    ).then((m) => m.RecruitDetailComponent),
                canDeactivate: [ConfirmLeaveGuard],
            },
        ],
    },
    // {
    //     path: 'tuyen-dung/:id/:customLink',
    //     loadComponent: () =>
    //         import(
    //             ''
    //         ).then((m) => m.PostRecruitComponent),
    // },
    {
        path: 'contact',
        loadComponent: () =>
            import('../../pages/contact/contact.component').then(
                (m) => m.ContactComponent
            ),
        title: 'Contact',
        canDeactivate: [ConfirmLeaveGuard],
    },
    {
        path: 'lien-he',
        loadComponent: () =>
            import('../../pages/contact/contact.component').then(
                (m) => m.ContactComponent
            ),
        title: 'Liên hệ',
        canDeactivate: [ConfirmLeaveGuard],
    },

    {
        path: '**',
        component: ErrorNotFoundComponent,
        title: 'Không tìm thấy đường dẫn',
    },
];

// export function createRoutes(languageService: LanguageService): Routes {
//     const lang = languageService.getLanguage();

//     return [
//         {
//             path: lang === 'en' ? 'en' : '',
//             pathMatch: 'full',
//             redirectTo: lang === 'en' ? 'en' : '',
//             title: lang === 'en' ? 'Home' : 'Trang chủ',
//         },
//         {
//             path: '',
//             loadComponent: () =>
//                 import('../../pages/home/home.component').then(
//                     (m) => m.HomeComponent
//                 ),
//             title: 'Trang chủ',
//         },
//         {
//             path: 'en',
//             loadComponent: () =>
//                 import('../../pages/home/home.component').then(
//                     (m) => m.HomeComponent
//                 ),
//             title: 'Home',
//         },
//         {
//             path: 'gioi-thieu',
//             loadComponent: () =>
//                 import('../../pages/introduce/introduce.component').then(
//                     (m) => m.IntroduceComponent
//                 ),
//             title: 'Giới Thiệu',
//         },
//         {
//             path: 'introduce',
//             loadComponent: () =>
//                 import('../../pages/introduce/introduce.component').then(
//                     (m) => m.IntroduceComponent
//                 ),
//             title: 'Introduce',
//         },
//         {
//             path: 'van-hoa',
//             loadComponent: () =>
//                 import('../../pages/culture/culture.component').then(
//                     (m) => m.CultureComponent
//                 ),
//             title: 'Văn hóa',
//         },
//         {
//             path: 'culture',
//             loadComponent: () =>
//                 import('../../pages/culture/culture.component').then(
//                     (m) => m.CultureComponent
//                 ),
//             title: 'Culture',
//         },
//         {
//             path: 'ecosystem',
//             children: [
//                 {
//                     path: 'fidra',
//                     loadComponent: () =>
//                         import(
//                             '../../pages/fidra-ios/fidra-ios.component'
//                         ).then((m) => m.FidraIosComponent),
//                     title: 'Fidra',
//                 },
//                 {
//                     path: 'govo-tech',
//                     loadComponent: () =>
//                         import(
//                             '../../pages/govo-tech/govo-tech.component'
//                         ).then((m) => m.GovotechComponent),
//                     title: 'Govo Tech',
//                 },
//                 {
//                     path: 'jacat-games',
//                     loadComponent: () =>
//                         import(
//                             '../../pages/jacat-games/jacat-games.component'
//                         ).then((m) => m.JacatGamesComponent),
//                     title: 'Jacat Games',
//                 },
//             ],
//         },
//         {
//             path: 'su-kien',
//             children: [
//                 {
//                     path: '',
//                     loadComponent: () =>
//                         import('../../pages/event/event.component').then(
//                             (m) => m.EventComponent
//                         ),
//                     title: 'Sự kiện',
//                 },
//                 {
//                     path: ':id',
//                     loadComponent: () =>
//                         import('../../pages/event/event.component').then(
//                             (m) => m.EventComponent
//                         ),
//                     title: 'Sự kiện',
//                 },
//             ],
//         },
//         {
//             path: 'event',
//             children: [
//                 {
//                     path: '',
//                     loadComponent: () =>
//                         import('../../pages/event/event.component').then(
//                             (m) => m.EventComponent
//                         ),
//                     title: 'Event',
//                 },
//                 {
//                     path: ':id',
//                     loadComponent: () =>
//                         import('../../pages/event/event.component').then(
//                             (m) => m.EventComponent
//                         ),
//                     title: 'Event',
//                 },
//             ],
//         },
//         {
//             path: 'hoat-dong',
//             loadComponent: () =>
//                 import('../../pages/activity/activity.component').then(
//                     (m) => m.ActivityComponent
//                 ),
//             title: 'Hoạt động',
//         },
//         {
//             path: 'activity',
//             loadComponent: () =>
//                 import('../../pages/activity/activity.component').then(
//                     (m) => m.ActivityComponent
//                 ),
//             title: 'Activity',
//         },
//         {
//             path: 'tuyen-dung',
//             children: [
//                 {
//                     path: '',
//                     loadComponent: () =>
//                         import('../../pages/recruit/recruit.component').then(
//                             (m) => m.RecruitComponent
//                         ),
//                     title: 'Tuyển Dụng',
//                 },
//                 {
//                     path: ':id/:speacial',
//                     loadComponent: () =>
//                         import(
//                             '../../pages/recruit/children/recruit-detail.component'
//                         ).then((m) => m.RecruitDetailComponent),
//                     canDeactivate: [ConfirmLeaveGuard],
//                 },
//             ],
//         },
//         {
//             path: 'recruit',
//             children: [
//                 {
//                     path: '',
//                     loadComponent: () =>
//                         import('../../pages/recruit/recruit.component').then(
//                             (m) => m.RecruitComponent
//                         ),
//                     title: 'Recruit',
//                 },
//                 {
//                     path: ':id/:speacial',
//                     loadComponent: () =>
//                         import(
//                             '../../pages/recruit/children/recruit-detail.component'
//                         ).then((m) => m.RecruitDetailComponent),
//                     canDeactivate: [ConfirmLeaveGuard],
//                 },
//             ],
//         },
//         // {
//         //     path: 'tuyen-dung/:id/:customLink',
//         //     loadComponent: () =>
//         //         import(
//         //             ''
//         //         ).then((m) => m.PostRecruitComponent),
//         // },
//         {
//             path: 'contact',
//             loadComponent: () =>
//                 import('../../pages/contact/contact.component').then(
//                     (m) => m.ContactComponent
//                 ),
//             title: 'Contact',
//             canDeactivate: [ConfirmLeaveGuard],
//         },
//         {
//             path: 'lien-he',
//             loadComponent: () =>
//                 import('../../pages/contact/contact.component').then(
//                     (m) => m.ContactComponent
//                 ),
//             title: 'Liên hệ',
//             canDeactivate: [ConfirmLeaveGuard],
//         },

//         {
//             path: '**',
//             component: ErrorNotFoundComponent,
//             title: 'Không tìm thấy đường dẫn',
//         },
//     ];
// }

import { Injectable, inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class CommonTitleStrategy extends TitleStrategy {
    #title = inject(Title);
    #meta = inject(Meta);

    public override updateTitle(routerState: RouterStateSnapshot) {
        const title = this.buildTitle(routerState);
        this.#title.setTitle(title ? `${title} - Volio Group` : 'Volio Group');
        this.#meta.updateTag({
            name: 'title',
            content: title ? `${title} - Volio Group` : 'Volio Group',
        });
        this.#meta.updateTag({
            property: 'og:title',
            content: title ? `${title} - Volio Group` : 'Volio Group',
        });
        this.#meta.updateTag({
            property: 'og:site_name',
            content: title ? `${title} - Volio Group` : 'Volio Group',
        });
    }
}
